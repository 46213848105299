import { Dispatch, SetStateAction, useState } from 'react';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import Image from 'next/image';
import { twMerge } from 'tailwind-merge';
import currency from 'currency.js';
import { Icon } from '../icons';
import { Text } from '../typography';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  capitalizeAllFirstLetters,
  getListingsEnvironmentBaseUrl,
  RepliersStateToAbbreviatedProvinceMap,
  calculateTotalMonthlyPayment,
  cleanStreetName,
} from '@requity-homes/utils';

import {
  defaultDownPaymentAmount,
  defaultRentPercent,
  fivePercentSavingsTarget,
} from '../const';

interface ListingAddress {
  streetNumber: string;
  streetName: string;
  neighborhood: string;
  city: string;
  zip: string;
  state: string;
}

interface ListingDetails {
  numRooms: string;
  numBedrooms: string;
  sqft: string;
  yearBuilt: string;
  description: string;
  numBathrooms: string;
  garage: string;
  parkingType: string;
  heating: string;
  numParkingSpaces: string;
  moreInformationLink: string;
  roofMaterial: string;
  style: string;
  basement1: string;
  basement2: string;
}

interface Listing {
  mlsNumber: string;
  images: [string];
  listPrice: string;
  address: ListingAddress;
  details: ListingDetails;
}

const getInitialDownPayment = (homePrice: number, percentInDecimal: number) => {
  const initialDownPaymentAmount = homePrice * percentInDecimal;

  const computedInitialPayment =
    initialDownPaymentAmount < defaultDownPaymentAmount
      ? defaultDownPaymentAmount
      : initialDownPaymentAmount;
  return computedInitialPayment;
};

interface ListingListItemProps {
  listing: Listing;
  className?: string;
  repliersCdnUrl: string;
  selectedListingId?: string;
  setActiveListing?: Dispatch<SetStateAction<Listing | null>>;
  setSelectedListingId?: Dispatch<SetStateAction<string | null>>;
  shouldOpenInNewWindow?: boolean;
  handleFavoriteBtnClick?: (lst: any) => Promise<any>;
  isRenderedOnMap?: boolean;
  favoriteListingIds?: string[];
}

const baseUrl = getListingsEnvironmentBaseUrl(
  process.env['NEXT_PUBLIC_STAGE']!,
);

const buildListingPageUrl = (listing: Listing): string => {
  const province = RepliersStateToAbbreviatedProvinceMap[listing.address.state];
  const city = listing.address.city.toLowerCase().replace(/ /g, '-');
  const mlsNumber = listing.mlsNumber.toLowerCase();
  const streetName = cleanStreetName(listing.address.streetName);

  return `${baseUrl}/${province}/${city}/rent-to-own/${mlsNumber}/${streetName}`;
};

export function ListingListItem({
  listing,
  className,
  repliersCdnUrl,
  selectedListingId,
  setSelectedListingId,
  setActiveListing,
  shouldOpenInNewWindow,
  handleFavoriteBtnClick,
  isRenderedOnMap,
  favoriteListingIds,
}: ListingListItemProps) {
  const [showFavoriteTooltipText, setShowFavoriteTooltipText] = useState(false);

  const homePrice = Number(listing.listPrice);
  const { totalMonthlyPayment } = calculateTotalMonthlyPayment(
    homePrice,
    defaultRentPercent,
    fivePercentSavingsTarget,
  );

  const numBedrooms = listing?.details.numBedrooms;
  const numBathrooms = listing?.details.numBathrooms;

  const router = useRouter();
  // let urlPathWithoutQueryParams = `${router.asPath.split('?')[0]}`;
  //
  // if (urlPathWithoutQueryParams.includes('widget')) {
  //   urlPathWithoutQueryParams = urlPathWithoutQueryParams.replace(
  //     'widget',
  //     'rent-to-own',
  //   );
  // } else if (urlPathWithoutQueryParams.includes('rent-to-own')) {
  //   urlPathWithoutQueryParams =
  //     urlPathWithoutQueryParams.split('rent-to-own')[0] + 'rent-to-own';
  // } else if (urlPathWithoutQueryParams.includes('home-listings')) {
  //   urlPathWithoutQueryParams = `${baseUrl}/${
  //     RepliersStateToAbbreviatedProvinceMap[listing.address.state]
  //   }/${listing.address.city.toLowerCase().replace(/ /g, '-')}/rent-to-own`;
  // }

  const listingUrl = buildListingPageUrl(listing);

  const streetName = capitalizeAllFirstLetters(
    listing.address.streetName.toLowerCase(),
  );
  return (
    <NextLink key={listing.mlsNumber} passHref href={listingUrl}>
      <a
        target={shouldOpenInNewWindow ? '_blank' : '_self'}
        onMouseEnter={() => {
          if (setActiveListing && setSelectedListingId) {
            setActiveListing(listing);
            setSelectedListingId(listing.mlsNumber);
          }
        }}
        onMouseLeave={() => {
          if (setActiveListing && setSelectedListingId) {
            setActiveListing(null);
            setSelectedListingId(null);
          }
        }}
      >
        <div
          className={twMerge(
            'rounded-lg p-4 shadow-md-dark overflow-hidden',
            selectedListingId === listing.mlsNumber ? 'bg-coral-light' : '',
            isRenderedOnMap ? 'w-80' : '',
            className,
          )}
          data-listing-id={listing.mlsNumber}
        >
          <Image
            alt="House"
            className="w-full h-48 object-cover rounded-lg"
            src={`${repliersCdnUrl}/${listing.images[0]}?class=medium`}
            layout="responsive"
            width={300}
            height={200}
          />
          <div className="flex justify-between items-center mt-3">
            <div>
              <p className="text-sm text-gray-600">Starting at</p>
              <h2 className="text-lg font-semibold">
                {`${currency(totalMonthlyPayment, {
                  precision: 0,
                }).format()}`}
                /month
              </h2>
              <p className="text-sm text-gray-600">
                with{' '}
                {currency(
                  getInitialDownPayment(
                    Number(listing.listPrice),
                    defaultRentPercent,
                  ),
                  {
                    precision: 0,
                  },
                ).format()}{' '}
                down payment
              </p>
              <p className="text-sm text-gray-600">
                Home price:{' '}
                {currency(listing.listPrice, {
                  precision: 0,
                }).format()}
              </p>
              <p className="mt-2">
                {listing.address.streetNumber
                  ? `${listing.address.streetNumber} - `
                  : ''}
                {streetName}
              </p>
              <p className="text-sm text-gray-600">{`${numBedrooms} ${
                numBedrooms == '1' ? 'bed' : 'beds'
              } · ${numBathrooms} ${
                numBathrooms == '1' ? 'bath' : 'baths'
              }`}</p>
            </div>
            {handleFavoriteBtnClick && (
              <div className="self-end">
                <div
                  className="relative"
                  onClick={async (event) => {
                    event.preventDefault();
                    await handleFavoriteBtnClick(listing);
                  }}
                  onMouseEnter={() => setShowFavoriteTooltipText(true)}
                  onMouseLeave={() => setShowFavoriteTooltipText(false)}
                >
                  {showFavoriteTooltipText && (
                    <span className="absolute -top-6 -right-3 text-coral-dark bg-white px-1 py-0.5 rounded-sm">
                      <Text useCase="legal">
                        {favoriteListingIds?.includes(listing.mlsNumber)
                          ? 'Unfavorite'
                          : 'Favorite'}
                      </Text>
                    </span>
                  )}
                  {favoriteListingIds?.includes(listing.mlsNumber) ? (
                    <span className={shouldOpenInNewWindow ? 'mr-2' : ''}>
                      <Icon className="h-6 w-6" glyph="heart" />
                    </span>
                  ) : (
                    <span className={shouldOpenInNewWindow ? 'mr-2' : ''}>
                      <Icon className="h-6 w-6" glyph="outlinedHeart" />
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </a>
    </NextLink>
  );
}
